import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="w-full h-auto">
    <iframe
      className="w-full h-[500px]"
      src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="VPGenie"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;

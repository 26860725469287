import React, { Suspense } from "react";
import Spline from "@splinetool/react-spline";
import Logo from "../assets/logo.png";
const ScrenMain = ({ onChangeCard = () => {} }) => {
  const onSplineMouseDown = (e) => {
    onChangeCard(e.target.name);
  };
  return (
    <div className="w-screen h-screen absolute grid ">
      <Suspense
        fallback={
          <div className="flex flex-col items-center justify-center">
            <img src={Logo} className="mr-3 h-[3rem]" alt="VPGenie" />
          </div>
        }
      >
        <Spline
          scene="https://prod.spline.design/LQc2j1Zv7yZNnTAX/scene.splinecode"
          onSplineMouseDown={onSplineMouseDown}
        />
      </Suspense>
    </div>
  );
};

export default ScrenMain;

import React, { useState } from "react";
import Logo from "../assets/logo.png";

import { useNavigate } from "react-router-dom";

const NavBar = ({ activeSection, onSelectMenu, sendToContact = () => {} }) => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  const goTo = (route = "/") => {
    navigate(route);
  };

  const onOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const sendToContactMobile = () => {
    setOpenMenu(false);
    sendToContact();
  };

  const menu = (
    <>
      <li>
        <button
          className={`block py-2 pl-3 pr-4 ${
            activeSection === "home"
              ? "text-[#02b24f] border-b border-[#02b24f]"
              : "text-white "
          } hover:text-[#02b24f] `}
          onClick={() => goTo("/")}
          aria-current="page"
        >
          Story Genie
        </button>
      </li>
      <li>
        <button
          className={`block py-2 pl-3 pr-4 ${
            activeSection === "studio"
              ? "text-[#02b24f] border-b border-[#02b24f]"
              : "text-white "
          }  hover:text-[#02b24f] `}
          onClick={() => goTo("/studio")}
        >
          Studio Genie
        </button>
      </li>
      <li>
        <button
          className={`block py-2 pl-3 pr-4 ${
            activeSection === "unreal"
              ? "text-[#02b24f] border-b border-[#02b24f]"
              : "text-white "
          }  hover:text-[#02b24f] `}
          onClick={() => goTo("/unreal")}
        >
          Unreal Genie
        </button>
      </li>

      <li className="mt-8">
        <button
          className="border rounded-lg px-4 py-2 text-lg text-white flex∫ lg:hidden"
          onClick={() =>sendToContactMobile()}
        >
          Contact Now
        </button>
      </li>
    </>
  );

  return (
    <>
      <nav className="bg-[#2D2D2D] px-2 sm:px-4 fixed w-full z-40  py-2">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <button className="flex items-center" onClick={() => goTo("/")}>
            <img src={Logo} className="mr-3 h-[3rem]" alt="VPGenie" />
          </button>
          <div className="flex order-1 smmd:order-2 flex-row gap-2">
            <a
              className=" px-4 py-2 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-xs text-white flex items-center justify-center"
              href="https://studio.vpgenie.app/"
            >
              Try Genie Now
            </a>
            <button
              className="border rounded-lg px-4 py-2 text-xs text-white hidden lg:flex"
              onClick={sendToContact}
            >
              Contact Now
            </button>

            <button
              type="button"
              className="lg:hidden"
              onClick={() => onOpenMenu()}
            >
              {!openMenu ? (
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.66797 14.6663H20.3346M1.66797 7.99967H20.3346M1.66797 1.33301H20.3346"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6654 4.21301L17.7854 0.333008L10.9987 7.11967L4.21203 0.333008L0.332031 4.21301L7.1187 10.9997L0.332031 17.7863L4.21203 21.6663L10.9987 14.8797L17.7854 21.6663L21.6654 17.7863L14.8787 10.9997L21.6654 4.21301Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className={`block w-auto  smmd:w-full smmd:smmd:order-2`}>
            <ul className="fflex-row justify-center gap-3 hidden lg:flex text-sm">
              {menu}
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={`fixed bg-black h-screen w-screen top-0 left-0 z-30 lg:hidden ${
          openMenu ? "" : "hidden"
        }`}
      >
        <ul className=" w-full flex flex-col items-center py-8 gap-8 justify-center h-full">
          {menu}
        </ul>
      </div>
    </>
  );
};
export default NavBar;

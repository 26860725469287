import React from "react";
import {
  ErrorPage,
  MainPage,
  LinkTree,
  StudioPage,
  UnrealPage
} from "../pages";


export const getMainRouter = () => {
  return [
    {
      path: "/",
      element: <MainPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "studio",
      element: <StudioPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "unreal",
      element: <UnrealPage />,
      errorElement: <ErrorPage />,
    },
   
    {
      path: "linktree",
      element: <LinkTree />,
      errorElement: <ErrorPage />
    }
  ];
};

import { useEffect, useState } from "react";
import Interco_logo from "../assets/interco.png";
import linkedin from "../assets/LINKEDIN.svg";
import web from "../assets/WEB.png";
import instagram from "../assets/IG.png";
import youtube from "../assets/YOUTUBE.png";
function LinkTree() {
  const [videoPositionOver, setVideoPositionOver] = useState(true);
  useEffect(
    () => {
      let timer1 = setTimeout(() => setVideoPositionOver(false), 3000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  return (
    <>
      <div
        className={`w-screen h-screen bg-black ${
          videoPositionOver ? "fixed z-10" : "absolute"
        } `}
      >
        <video loop muted autoPlay={true} className="w-screen h-screen">
          <source
            src="https://s3.us-west-1.amazonaws.com/photoiatest.data.assets/background/VP+Genie+Segundo+Final.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="bg-black opacity-40 fixed w-screen h-screen"></div>
      <div
        className={` w-screen h-screen  text-white smmd:p-4 font-mono overflow-scroll ${
          !videoPositionOver ? "fixed z-10" : ""
        } `}
      >
        <div className="flex flex-col gap-3 justify-center items-center pt-28 smmd:pt-4 overflow-scroll">
          <img
            src={Interco_logo}
            className="h-[86px] smmd:h-[86px]"
            alt="VPGenie"
          />
          <p className="font-bold w-[426px] text-[22px] py-2 px-4 leading-[29px] text-center smmd:w-auto">
            You <span className="text-[#4593CC]">dream</span> it, we{" "}
            <span className="text-[#4593CC]">build</span> it, and you keep the{" "}
            <span className="text-[#4593CC]">code</span>.
          </p>
          <p className="w-[1082px] smmd:w-auto smmd:text-sm py-8 px-4 text-center">
            Unlock your creative potential with Interco.ai -{" "}
            <span className="text-[#4593CC]">
              the expert in generative AI software tools
            </span>
            . We specialize in building custom models, artwork, Unreal
            environments, mobile apps, dashboards, and video game development
            for the entertainment industry. From VPGenie to AR/VR
            experiences, we've got you covered. Let's create something amazing
            together!
          </p>
          <div className="flex flex-col items-center">
            <span className="font-bold leading-0 text-2xl">Bernard Mendez</span>
            <span className="text-2xl">Phone : (512) 593-8479</span>
            <span className="text-2xl"> Mail : bernard@interco.ai</span>
          </div>

          <div className="flex flex-col items-center gap-3">
            <a
              className=" px-40 bg-[#4593CC] rounded py-2.5 text-center inline-flex items-center"
              href="https://www.linkedin.com/in/bmendez6/"
              target="_blank"
              rel="noreferrer"
            >
              <span>Linkedin</span>{" "}
              <img
                src={linkedin}
                alt="VPGenie"
                className="pl-2 pr-1"
              />
            </a>
            <a
              className=" px-[123px] bg-[#4593CC] rounded py-2.5 text-center inline-flex items-center"
              href="https://www.linkedin.com/company/interco-industries"
              target="_blank"
              rel="noreferrer"
            >
              <span>Linkedin Interco</span>{" "}
              <img
                src={linkedin}
                alt="VPGenie"
                className="pl-2 pr-1"
              />
            </a>
            <a
              className="px-40 text-center inline-flex items-center bg-[#2D2D2D]  rounded py-2.5"
              href="https://www.interco.ai/"
              target="_blank"
              rel="noreferrer"
            >
              Web Site
              <img src={web} alt="VPGenie" className="pl-2 pr-3" />
            </a>
            <a
              className="px-40 text-center inline-flex items-center bg-[#2D2D2D]  rounded py-2.5"
              href="https://www.instagram.com/interco.ai/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
              <img
                src={instagram}
                alt="VPGenie"
                className="pl-2 pr-1"
              />
            </a>
            <a
              className="px-40 text-center inline-flex items-center bg-[#2D2D2D]  rounded py-2.5"
              href="https://www.youtube.com/@intercoai274"
              target="_blank"
              rel="noreferrer"
            >
              Youtube
              <img
                src={youtube}
                alt="VPGenie"
                className="pl-2 pr-4"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default LinkTree;

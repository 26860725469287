import react, { useRef, useEffect } from "react";
import { NavBar, Footer } from "../components";
import { Home, Contact, ComplementHome } from "./Sections";

function MainPage() {
  const contact = useRef();
  const top = useRef();

  useEffect(() => {
    top.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const sendToContact = () => {
    contact.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div ref={top}>
      <NavBar activeSection="home" sendToContact={sendToContact} />
      <Home />
      <ComplementHome />
      <div ref={contact}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./storage/store";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTU9peUDdjYRuQfIFbuW6KQ6WBNzjcCPQ",
  authDomain: "vpgenie-7a214.firebaseapp.com",
  projectId: "vpgenie-7a214",
  storageBucket: "vpgenie-7a214.appspot.com",
  messagingSenderId: "609174920370",
  appId: "1:609174920370:web:7dda6b6033d935df00fdab",
  measurementId: "G-LX7VSVHLYK"
};

// Initialize Firebase
const appFB = initializeApp(firebaseConfig);
const analytics = getAnalytics(appFB);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
const InputText = ({
  value,
  name,
  label,
  type = "text",
  placeHolder,
  onChangeValue,
  classValue,
  showLabel = true,
  classInput = "",
  step = "",
  min = "",
  max = "",
  error = "",
}) => {
  return (
    <div className={classValue}>
      {showLabel && (
        <label
          htmlFor={name}
          className="block mb-1 text-sm smmd:text-xs  font-medium text-white"
        >
          {label}
        </label>
      )}

      <input
        type={type}
        id={name}
        name={name}
        value={value}
        step={step}
        min={min}
        max={max}
        onChange={(e) => onChangeValue(e.target.value, name)}
        className={`bg-white  bg-opacity-30 text-white text-sm smmd:text-xs  rounded block w-full p-2.5 ${classInput}`}
        placeholder={placeHolder}
        required
      />
      {error !== "" && (
        <span className="text-[12px] text-[#FF0000]">{error}</span>
      )}
    </div>
  );
};
export default InputText;

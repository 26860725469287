import React from "react";
import PropTypes from "prop-types";

const Modal = ({ fullScreen, children }) => (
  <div
    className={`flex flex-col gap-3 justify-center items-center backdrop-blur z-20 ${
      fullScreen
        ? "h-full w-full fixed top-0 left-0"
        : "absolute min-w-max min-h-max"
    } `}
  >
    {children}
  </div>
);

Modal.defaultProps = {
  fullScreen: false,
};

Modal.propTypes = {
  fullScreen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Modal;

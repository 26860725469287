const useContact = () => {
  const sendContact = async (data) => {
    var raw = JSON.stringify({
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      message: data.message,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    let hasError = false;

    await fetch(
      "https://5ra4y2f37phmryotrhstspdfyi0becuu.lambda-url.us-west-1.on.aws/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => hasError = false)
      .catch((error) => hasError = true);

    return {
      status: !hasError,
      message: !hasError ? "Thank you for getting in touch" : "Please try again.",
    };
  };

  return {sendContact};
};

export default useContact;

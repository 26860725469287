import { ScreenMain } from "../../components";

const ComplementStudio = ({ sendToContact = () => {} }) => {
  return (
    <div className="bg-black  w-full relative flex flex-col items-center">
      <div className="container pt-24 text-white flex flex-col lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1 flex flex-col gap-3 p-4">
          <p className=" text-2xl">Handle Your Crew</p>
          <span className="font-thin text-sm">
            Simplify crew management. VP Studio enables you to list all
            available crew members, assigning them to specific tasks and
            departments. Coordinate schedules, assignments, and availability to
            keep your production running smoothly.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/studio-genie-crew.png"
            alt=""
          />
        </div>
      </div>

      <div className="container pt-24 text-white flex flex-col lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/studio-genie-cast.png"
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col gap-3 p-4 ">
          <p className=" text-2xl">Manage your Cast</p>
          <span className="font-thin text-sm">
            Effortlessly manage your team. VP Studio allows you to list all
            available cast members, assigning them to specific roles. Keep
            everyone on the same page with integrated scheduling and
            communication tools.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
      </div>
      <div className="container pt-24 text-white flex flex-col lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1 flex flex-col gap-3 p-4">
          <p className=" text-2xl">Take your Scouting to the Next Level</p>
          <span className="font-thin text-sm">
            Simplify your location scouting process.VP Studio enables you to
            compare potential locations with those mentioned in your script
            breakdown. Upload location photos and details, and match them
            against script requirements to find the perfect fit for your scenes.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/studio-genie-locations.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ComplementStudio;

import { ScreenMain } from "../../components";

const HomeStudio = ({ sendToContact = () => {} }) => {
  return (
    <div className="bg-black  w-full relative flex flex-col items-center">
      <div className="fixed bg-bg-cards-grid bg-no-repeat bg-cover bg-center h-screen w-screen"></div>
      <div className="container pt-24 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]  text-white flex flex-col gap-2 z-20">
        <p className=" text-3xl">Meet Studio Genie</p>
        <span className="font-thin">
          Transform the way you manage your film production.
        </span>
        <div className="h-[400px] lg:h-[730px] w-full bg-studio-genie-dashboard bg-no-repeat bg-cover bg-top flex flex-col items-center justify-end py-10">
        <a
           href="https://studio.vpgenie.app/"
          className="px-8 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-lg mt-10 font-bold flex items-center justify-center"
        >
          Try Genie Now
        </a>
        </div>

        <span className="text-center px-10 font-thin">
          From script import to shot breakdown, from asset management to crew
          coordination, VP Studio is your all-in-one solution for seamless and
          efficient film production.
        </span>
      </div>
    </div>
  );
};

export default HomeStudio;

import { YoutubeEmbed } from "../../components";
import { useNavigate } from "react-router-dom";
const ComplementHome = ({ sendToContact = () => {} }) => {
  const navigate = useNavigate();
  const goTo = (route = "/") => {
    navigate(route);
  };
  return (
    <div className="bg-black  w-full relative flex flex-col items-center pb-16">
      <div className="container p-4 h-[600px] pt-24">
        <YoutubeEmbed embedId="16snA8e3PYs" />
      </div>
      <div className="container pt-24 text-white flex flex-col lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1 flex flex-col gap-3 p-4 justify-center">
          <p className=" text-2xl">Take your Scouting to the Next Level</p>
          <span className="font-thin text-sm">
            Simplify your location scouting process.VP Studio enables you to
            compare potential locations with those mentioned in your script
            breakdown. Upload location photos and details, and match them
            against script requirements to find the perfect fit for your scenes.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-8 py-4 bg-gradient-to-r  lg:hidden from-[#02b24f] to-[#1b5990] rounded-lg text-lg mt-10 font-bold flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/studio-genie-locations.png"
            alt=""
          />
        </div>
      </div>
      <div className="container pb-24 pt-14 text-white flex flex-col gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <p className="text-2xl text-center py-2">Unreal + Genie </p>
        <div className="grid lg:grid-cols-5 gap-4">
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Generate</span>
            <span className="text-xs">
              Generate quick high quality AI images to help you visualize and
              paint your vision.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Inpainting</span>
            <span className="text-xs">
              Edit images and textures to your liking painting over them and
              tell us what to change from it.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Skybox</span>
            <span className="text-xs">
              Envision quick and easy lighting and sky setups with our AI
              generate skyboxes.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Upscale</span>
            <span className="text-xs">
              Is your image or texture ready to be used, make it larger without
              loosing any of the good looks.
            </span>
          </div>
          <div className="border border-[#dada14] rounded-md flex flex-col p-2 gap-1">
            <span>Segment</span>
            <span className="text-xs">
              Easily create previs by combining the power of Image Gen with
              Segmentation, creating quick silhouettes, characters and props.
            </span>
          </div>
        </div>
        <div className="flex items-center w-full justify-center">
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComplementHome;

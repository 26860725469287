import { ScreenMain } from "../../components";

const ComplementUnreal = ({ sendToContact = () => {} }) => {
  return (
    <div className="bg-black  w-full relative flex flex-col items-center gap-6">
      <div className="container pt-24 text-white flex flex-col gap-2">
        <p className="text-4xl text-center py-2">Unreal Genie Features</p>
      </div>
      <div className="container text-white flex flex-col lg:flex-row gap-2 items-center lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/unreal-genie-script.png"
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col gap-3 p-4">
          <p className=" text-2xl">Take your script to the 3D World</p>
          <span className="font-thin text-sm">
            Quickly assemble scenes using your in-engine and at-hand Shot List
            as a guide. To make the most out of your pipeline, we’ll handle all
            of the tedious work.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
      </div>
      <div className="container text-white flex flex-col lg:flex-row gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <div className="lg:w-2/5 flex flex-col gap-3 p-4">
          <p className=" text-2xl">
            Save the travel costs and do virtual scouting in amazing 3D worlds
          </p>
          <span className="font-thin text-sm">
            Feel comfortable in-engine with the help from your AI teammate, VP
            Genie. Do what you do best, imagine worlds, create astounding
            experiences, experiment with new setups; well be there to help you
            out.
          </span>
          <a
            href="https://studio.vpgenie.app/"
            className="px-16 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-md mt-10  flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>
        <div className="flex-1">
          <img
            src="https://s3.amazonaws.com/vpgenie.output.assets/webpage/vpgenie/unreal-genie-scout.png"
            alt=""
          />
        </div>
      </div>
      <div className="container pb-24 text-white flex flex-col gap-2 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px]">
        <p className="text-2xl text-center py-2">Unreal + Genie </p>
        <div className="grid lg:grid-cols-5 gap-4">
          <div className="border border-[#02b24f] rounded-md flex flex-col p-2 gap-1">
            <span>Generate</span>
            <span className="text-xs">
              Generate quick high quality AI images to help you visualize and
              paint your vision.
            </span>
          </div>
          <div className="border border-[#02b24f] rounded-md flex flex-col p-2 gap-1">
            <span>Inpainting</span>
            <span className="text-xs">
              Edit images and textures to your liking painting over them and
              tell us what to change from it.
            </span>
          </div>
          <div className="border border-[#02b24f] rounded-md flex flex-col p-2 gap-1">
            <span>Skybox</span>
            <span className="text-xs">
              Envision quick and easy lighting and sky setups with our AI
              generate skyboxes.
            </span>
          </div>
          <div className="border border-[#02b24f] rounded-md flex flex-col p-2 gap-1">
            <span>Upscale</span>
            <span className="text-xs">
              Is your image or texture ready to be used, make it larger without
              loosing any of the good looks.
            </span>
          </div>
          <div className="border border-[#02b24f] rounded-md flex flex-col p-2 gap-1">
            <span>Segment</span>
            <span className="text-xs">
              Easily create previs by combining the power of Image Gen with
              Segmentation, creating quick silhouettes, characters and props.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplementUnreal;

import React from "react";
import loaderGenie from "../assets/loaderGenie.gif";

import PropTypes from "prop-types";

const Loader = ({ fullScreen, text = "" }) => (
  <div
    className={`flex flex-col gap-3 justify-center items-center backdrop-blur z-50 ${
      fullScreen
        ? "h-full w-full fixed top-0 left-0"
        : "absolute min-w-max min-h-max"
    } `}
  >
    <img
      src={loaderGenie}
      className="h-[400px]"
      alt="VPGenie"
    />
    <span className="pt-2 text-lg text-white">Genie is granting your wish...</span>
  </div>
);

Loader.defaultProps = {
  fullScreen: false,
};

Loader.propTypes = {
  fullScreen: PropTypes.bool,
};

export default Loader;

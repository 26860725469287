import React, { useState } from "react";
import { ScreenMain } from "../../components";

const Home = () => {
  const [currentCard, setCurrentCard] = useState("Story");
  const onChangeCard = (c) => {
    console.log(c);
    setCurrentCard(c);
  };
  return (
    <div className="bg-black h-screen w-full relative">
      <ScreenMain onChangeCard={onChangeCard} />
      <div className="absolute flex flex-col items-center justify-end z-20 h-1/4 bottom-24 lg:right-1/4 lg:left-1/4 px-8 lg:px-0 w-full lg:w-3/5 lg:bottom-64  text-white">
        {currentCard === "Characters" && (
          <div className=" flex flex-col gap-2 text-center">
            <span className=" text-2xl lg:text-6xl font-bold">
              DESIGN CHARACTERS
            </span>
            <span className="text-sm lg:text-lg px-8">
              With our advanced AI-powered character generator, you can create
              unique and detailed characters in just minutes. Whether for your
              film, television, video game projects, or any other audiovisual
              production, our tool offers endless possibilities. Customize every
              aspect of your characters, from their physical appearance to their
              backstory, and watch them come to life before your eyes.
            </span>
          </div>
        )}
        {currentCard === "Locations" && (
          <div className=" flex flex-col gap-2 text-center">
            <span className="text-2xl lg:text-6xl font-bold">
              CREATE AMAZING LOCATIONS
            </span>
            <span className="text-sm lg:text-lg px-8">
              With our cutting-edge AI-powered location generator, you can
              design diverse and intricate settings in mere minutes. Customize
              every facet of your locations, from their geographical features to
              their historical significance, and see them materialize vividly
              before you.
            </span>
          </div>
        )}
        {currentCard === "Story" && (
          <div className=" flex flex-col gap-2 text-center">
            <span className="text-2xl lg:text-6xl font-bold">
              FROM CONCEPT TO SHOTLIST
            </span>
            <span className="text-sm lg:text-lg px-8">
              Our state-of-the-art AI-powered storyboard generator enables you
              to develop comprehensive and engaging storyboards quickly and
              efficiently. Tailor each aspect of your storyboard, from the
              arrangement of scenes to the dynamics between characters, and
              visualize your narrative with clarity and creativity.
            </span>
          </div>
        )}
        <a
           href="https://studio.vpgenie.app/"
          className="px-8 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-lg mt-10 font-bold flex items-center justify-center"
        >
          Try Genie Now
        </a>
      </div>
    </div>
  );
};

export default Home;

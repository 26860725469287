import { NavBar, Footer } from "../components";
function ErrorPage() {

  return (
    <>
      <NavBar/>
      <div className="bg-black">
        <div className="mb-3  pt-[72px]">
          ERROR
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ErrorPage;

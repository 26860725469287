import { ScreenMain } from "../../components";

const HomeUnreal = ({ sendToContact = () => {} }) => {
  return (
    <div className="bg-black  w-full relative flex flex-col items-center">
      <div className="fixed bg-bg-cards-grid bg-no-repeat bg-cover bg-center h-screen w-screen"></div>
      <div className="container pt-24 lg:pb-0 lg:pr-0 lg:pl-0 p-[15px] text-white flex flex-col gap-2 z-20">
        <p className=" text-3xl">Unreal Genie</p>
        <span className="font-thin">
          Import your projects directly into Unreal Engine
        </span>
        <div className="h-[400px] lg:h-[730px] w-full bg-unreal-genie-main bg-no-repeat bg-cover bg-top flex flex-col items-center justify-end py-10">
          <a
            href="https://studio.vpgenie.app/"
            className="px-8 py-4 bg-gradient-to-r from-[#02b24f] to-[#1b5990] rounded-lg text-lg mt-10 font-bold flex items-center justify-center"
          >
            Try Genie Now
          </a>
        </div>

        <span className="text-center px-10 font-thin">
          Get your cameras, scenes, background images and DAY/NIGHT settings
          from your script with AI, taking away the guess work from creating a
          new Unreal Project for your production
        </span>
      </div>
    </div>
  );
};

export default HomeUnreal;

import Interco_logo from "../assets/interco.png";
const Footer = () => {
  return (
    <nav className=" bg-[#2D2D2D] flex flex-col items-center  ">
      <div className="container flex flex-row justify-center py-4 lg:px-0 px-8">
        <img src={Interco_logo} className="h-8" alt="VPGenie" />
      </div>
    </nav>
  );
};
export default Footer;

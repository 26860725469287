import react, { useRef, useEffect } from "react";
import { NavBar, Footer } from "../components";
import { HomeStudio,ComplementStudio, Contact } from "./Sections";

function StudioPage() {
  const contact = useRef();
  const top = useRef();

  useEffect(() => {
    top.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const sendToContact = () => {
    contact.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div ref={top}>
      <NavBar activeSection="studio" sendToContact={sendToContact} />
      <HomeStudio sendToContact={sendToContact} />
      <ComplementStudio sendToContact={sendToContact} />
      <div ref={contact}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default StudioPage;
